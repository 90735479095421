import { graphql } from "gatsby";
import { first, get, isNil } from "lodash";
import React from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import FacetedSearch from "../../components/facetedsearch";
import Hero from "../../components/hero";
import Intro from "../../components/intro/wildcard";
import { FilterProvider } from "../../contexts/filter-provider";
import {
  TideItemForHeroComponent,
  TideItemForImage,
  TideItemForOverviewComponent,
  TideItemForIntroComponent,
  TideItemForInfoBlocksComponent,
  TideItemForTagPage,
  TideItemForWebsite,
  TideItemForUspFolder,
} from "../../types";
import { getChildItemsWithTypename, parse } from "../component";
import MapBlocksComponents from "./map-blocks-components";

interface MapTagPageComponentsProps {
  website: TideItemForWebsite;
  page: TideItemForTagPage;
  usps: TideItemForUspFolder;
  breadcrumbs: Breadcrumb[];
}

const MapTagPageComponents: React.FC<MapTagPageComponentsProps> = ({ website, page, usps, breadcrumbs }) => {
  const componentRenderers = {
    TideItemForHeroComponent: (componentItem: TideItemForHeroComponent) => {
      const images = getChildItemsWithTypename("TideItemForImage", componentItem) as TideItemForImage[];
      return (
        <Hero
          key={componentItem.id}
          image={parse(first(images)?.content?.general?.image)}
          breadcrumbs={breadcrumbs}
          showQsm={parse(componentItem.content?.general?.showQsm)}
          qsmSlimVersion={true}
          page={page.name}
          extraClass={!componentItem.content?.general?.showQsm ? "header--compact" : ""}
        />
      );
    },
    TideItemForOverviewComponent: (componentItem: TideItemForOverviewComponent) => {
      return (
        <FilterProvider key={componentItem.id}>
          <FacetedSearch itemsPerPage={12}></FacetedSearch>
        </FilterProvider>
      );
    },
    TideItemForIntroComponent: (componentItem: TideItemForIntroComponent) => {
      return (
        <Intro
          key={componentItem.id}
          text={parse(componentItem.content?.general?.text)}
          title={parse(componentItem.content?.general?.title)}
          website={parse(website)}
          usps={parse(usps)}
        />
      );
    },
    TideItemForInfoBlocksComponent: (componentItem: TideItemForInfoBlocksComponent) => {
      return <MapBlocksComponents key={componentItem.id} blocks={parse(componentItem.childItems)} games={[]} />;
    },
  };

  return (
    <>
      {page?.childItems?.map((tideItem) => {
        const typeName = get(tideItem, "__typename");
        const renderer = get(componentRenderers, typeName);

        if (!isNil(renderer)) {
          return renderer(tideItem);
        }

        return null;
      })}
    </>
  );
};

export default MapTagPageComponents;

export const query = graphql`
  fragment TagPageOverviewComponentFragment on TideItemForOverviewComponent {
    __typename
    id
    content {
      general {
        title
      }
    }
  }
`;
